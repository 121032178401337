import React, { useState, useEffect } from 'react';
import { Router } from '@reach/router';
import Layout from '../components/Layout';
import { navigate } from '@reach/router';
import NewVideo from '../components/NewVideo';
import CreateUserForm from '../components/CreateUserForm';
import MenuLink from '../components/MenuLink';
import UserList from '../components/UserList';
import { useFirebase } from '../state';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  .sidebar {
    width: 100%;
    padding: 10px;
  }

  .main {
    width: 100%;
    padding: 10px;
  }

  @media only screen and (min-width: 800px) {
    .sidebar {
      width: 25%;
    }

    .main {
      width: 75%;
    }
  }
`;

export default function () {
  const { firebase, user } = useFirebase();
  const [users, setUsers] = useState(null);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  let ErrorDiv = function () {
    if (error) {
      return (
        <div className="notification is-danger">
          <button className="delete" onClick={() => setError(null)}></button>
          {error}
        </div>
      );
    } else {
      return null;
    }
  };

  let MessageDiv = function () {
    if (message) {
      return (
        <div className="notification is-success">
          <button className="delete" onClick={() => setMessage(null)}></button>
          {message}
        </div>
      );
    } else {
      return null;
    }
  };
  function compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.email.toUpperCase();
    const bandB = b.email.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  async function listUsers() {
    var func = firebase.functions().httpsCallable('firebase_list_users');
    try {
      let res = await func({});
      let users = res.data;
      console.log('Sorting users');
      let out = users.sort(compare);
      setUsers(users);
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
  }

  async function createUser(email, password) {
    let payload = { email: email, password: password };
    var func = firebase.functions().httpsCallable('firebase_create_user');
    try {
      await func(payload);
      setMessage('User created');
      navigate('/admin/users');
      listUsers();
    } catch (err) {
      console.log(err);
      console.log('error message: ' + err.message);
      setError(err.message);
    }
  }

  async function deleteUser(uid) {
    console.log('Deleting user: ' + uid);
    let payload = { uid: uid };
    var func = firebase.functions().httpsCallable('firebase_delete_user');
    try {
      await func(payload);
      setMessage('User deleted');
      listUsers();
    } catch (err) {
      console.log(err);
      console.log('error message: ' + err.message);
      setError(err.message);
    }
  }

  async function toggleActivation(uid) {
    let payload = { uid: uid };
    var func = firebase.functions().httpsCallable('firebase_toggle_activation');
    try {
      await func(payload);
      setMessage('User activated');
      listUsers();
    } catch (err) {
      console.log(err);
      console.log('error message: ' + err.message);
      setError(err.message);
    }
  }

  useEffect(() => {
    listUsers();
  }, []);

  if (!user) {
    return null;
  }

  if (!user.admin) {
    return null;
  }

  return (
    <Layout title="Admin - The Yoga Practice">
      <section className="pt4 pb4">
        <div className="container">
          <Wrapper>
            <div className="sidebar">
              <aside className="menu w-100 box">
                <p className="menu-label">Admin Menu</p>
                <ul className="menu-list">
                  <li>
                    <MenuLink to="">New video</MenuLink>
                  </li>
                  <li>
                    <MenuLink to="users">Manage Users</MenuLink>
                  </li>
                  <li>
                    <MenuLink to="new-user">New User</MenuLink>
                  </li>
                </ul>
              </aside>
            </div>
            <div className="main">
              <ErrorDiv msg={error} />
              <MessageDiv msg={message} />
              <Router>
                <NewVideo path="/" />
                <UserList
                  path="users"
                  users={users}
                  deleteHandler={deleteUser}
                  activateHandler={toggleActivation}
                />
                <CreateUserForm path="new-user" submitHandler={createUser} />
              </Router>
            </div>
          </Wrapper>
        </div>
      </section>
    </Layout>
  );
}
