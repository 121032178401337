import React, { useState } from 'react';
import styled from 'styled-components';

const Form = styled.form`
  display: flex;
  align-items:center;
  flex-direction: column;
`;

const LoginForm = function (props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  async function signIn(e) {
    e.preventDefault();
    props.submitHandler(email, password);
  }

  return (
    <Form onSubmit={signIn}>
      <div className="field">
        <div className="control ">
          <input
            className="input w5"
            type="text"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter Email"
          />
        </div>
      </div>
      <div className="field">
        <div className="control">
          <input
            className="input w5"
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter Password"
          />
        </div>
      </div>

      <div className="field">
        <div className="control">
          <button className="button is-success" id="loginSubmit">
            Sign In
          </button>
        </div>
      </div>
    </Form>
  );
};
export default LoginForm;
