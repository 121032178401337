import React from 'react';

export default function (props) {
  if (props.users == null) {
    return <progress className="progress is-success"></progress>;
  }
  return (
    <div className="box">
      <table className="table w-100" style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th>Email</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.users.map((item, index) => (
            <tr key={item.uid}>
              <td>
                {item.email}
                {item.customClaims.admin ? (
                  <span className="ml2 tag">admin</span>
                ) : null}
                {item.customClaims.active ? (
                  <span className="ml2 tag is-success">active</span>
                ) : null}
              </td>
              <td>
                <button
                  className="button is-danger"
                  onClick={(e) => props.deleteHandler(item.uid)}
                >
                  Delete
                </button>
                {!item.customClaims.admin ? (
                  <button
                    className="button ml2"
                    onClick={(e) => {
                      props.activateHandler(item.uid);
                    }}
                  >
                    {item.customClaims.active ? 'Deactivate' : 'Activate'}
                  </button>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
