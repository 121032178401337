import React from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
`;

function Player(props) {
  if (props.muxPlaybackId) {
    return (
      <PlayerWrapper>
        <ReactPlayer
          url={`https://stream.mux.com/${props.muxPlaybackId}.m3u8`}
          light={`https://image.mux.com/${props.muxPlaybackId}/thumbnail.jpg`}
          playing={true}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
          }}
          controls={true}
          width="100%"
          height="100%"
          className="react-player"
          config={{
            file: { attributes: { controlsList: 'nodownload' } },
          }}
          onContextMenu={e => {
            e.preventDefault();
            console.log(props.muxPlaybackId);
          }}
        />
      </PlayerWrapper>
    );
  } else {
    return <div className="notification is-danger">Not found</div>;
  }
}
export default Player;
