import React from 'react';
import 'bulma/css/bulma.css';
import 'tachyons/css/tachyons.css';
import {
  FaSignOutAlt,
  FaUser,
  FaVideo,
  FaCarrot,
  FaHome,
} from 'react-icons/fa';
import { Link } from '@reach/router';
import AppStoreLink from './AppStoreLink';
import { useFirebase } from '../state';
import styled from 'styled-components';

// Footer set using this example: https://www.freecodecamp.org/news/how-to-keep-your-footer-where-it-belongs-59c6aa05c59c/

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  .header {
    padding-bottom: 400px;
  }
  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .row {
    display: flex;
  }

  .column {
    flex: 50%;
  }

  .footer-link {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 5px;
  }

  @media only screen and (min-width: 800px) {
    .footer-link {
      display: inline;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
`;

export default function Layout(props) {
  const { user, signOut } = useFirebase();
  document.title = props.title;

  let Nav = function () {
    return (
      <>
        {user != null ? (
          <>
            <Link to="/" className="footer-link">
              <button className="button">
                <FaVideo />
                <span className="ml2">Classes</span>
              </button>
            </Link>
            <Link to="/account" className="footer-link">
              <button className="button">
                <FaUser />
                <span className="ml2">Account</span>
              </button>
            </Link>
            <button
              className="button footer-link"
              onClick={(e) => {
                e.preventDefault();
                signOut();
              }}
            >
              <FaSignOutAlt />
              <span className="ml2">Sign Out</span>
            </button>
          </>
        ) : null}

        {user.admin === true ? (
          <>
            <Link to="/admin" className="footer-link">
              <button className="button">
                <FaCarrot />
                <span className="ml2">Admin</span>
              </button>
            </Link>
          </>
        ) : null}

        <AppStoreLink />

      </>
    );
  };

  return (
    <Wrapper>
      <div className="header">
        <section
          className="hero"
          style={{ backgroundColor: 'rgb(250, 250, 250)' }}
        >
          <div className="hero-body tc">
            <h1 className="title mt0">The Yoga Practice</h1>
            <h2 className="subtitle">Online Classes</h2>
            <Nav />
          </div>
        </section>
        <section className="main">
          <main>{props.children}</main>
        </section>
      </div>
      <footer className="footer">
        <div className="content has-text-centered">
          <p>
            <strong>Online Classes</strong> by{' '}
            <a href="https://theyogapractice.com.au">The Yoga Practice</a>
          </p>
          <p>&copy; 2020 by Lyn Beeby</p>
        </div>
      </footer>
    </Wrapper>
  );
}
