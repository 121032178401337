import React, { useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FaUpload } from 'react-icons/fa';
import { useFirebase } from '../state';

let ErrorDiv = function (props) {
  if (props.msg) {
    return <div className="notification is-danger">{props.msg}</div>;
  } else {
    return null;
  }
};

export default function () {
  const { firebase, user } = useFirebase();
  const [errorMessage, setErrorMessage] = useState(null);
  const [status, setStatus] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [file, setFile] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [instructor, setInstructor] = useState('');
  const fileInput = useRef();

  async function uploadFile(file, callback) {
    let storage = firebase.storage();
    let filePath = `videos/${uuidv4()}-${file.name}`;
    let gsRef = storage.ref(filePath);
    let uploadTask = gsRef.put(file);
    uploadTask.on('state_changed', callback);
    await uploadTask;

    let payload = {};
    payload.fileName = file.name;
    payload.filePath = filePath;
    payload.title = title;
    payload.description = description;
    payload.category = category;
    payload.instructor = instructor;
    payload.email = user.email;
    payload.published = false;
    payload.timestamp = firebase.firestore.FieldValue.serverTimestamp();
    try {
      await firebase.firestore().collection('videos-v2').doc().set(payload);
    } catch (err) {
      setErrorMessage(err.message);
      console.log(err);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    console.log('Uploading');
    setUploadProgress(1);
    setStatus(`Uploading ${file.name}`);
    await uploadFile(file, function (snapshot) {
      var progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      setUploadProgress(progress);
    });
    setStatus(null);
    setUploadProgress(null);
    setFile(null);
    setTitle('');
    setDescription('');
  }

  function fileSelected(event) {
    event.preventDefault();
    console.log(event.target.files[0]);
    setFile(event.target.files[0]);
  }

  return (
    <div className="pa4 box">
      <form onSubmit={handleSubmit}>
        <div className="field">
          <label className="label">Class Title</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Enter a title for this class"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">Class Description</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Enter a short description for this class"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">Category</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Enter a category for this class"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">Instructor</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Enter an instructor for this class"
              value={instructor}
              onChange={(e) => setInstructor(e.target.value)}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">Video File</label>
          <div className="control">
            <div className="file has-name is-fullwidth">
              <label className="file-label">
                <input
                  className="file-input"
                  type="file"
                  name="resume"
                  ref={fileInput}
                  onChange={fileSelected}
                />
                <span className="file-cta">
                  <span className="file-icon">
                    <FaUpload />
                  </span>
                  <span className="file-label">Choose a file…</span>
                </span>
                <span className="file-name">{file ? file.name : null}</span>
              </label>
            </div>
          </div>
        </div>

        <div className="field is-grouped" style={{ marginTop: '10px' }}>
          <div className="control">
            <button className="button is-link">Submit</button>
          </div>
        </div>
      </form>

      <ErrorDiv msg={errorMessage} />

      {uploadProgress ? (
        <>
          <div className="has-text-centered">{status}</div>
          <progress
            className="progress is-primary"
            value={uploadProgress}
            max="100"
          />
        </>
      ) : null}
    </div>
  );
}
