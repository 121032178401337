import React from 'react';

function LoadingDiv(props) {
  if (props.loading) {
    return <progress className="progress is-success"></progress>;
  }
  return null;
}

function ErrorDiv(props) {
  if (props.errorMsg) {
    return <div className="notification is-danger">{props.errorMsg}</div>;
  }
  return null;
}

export { LoadingDiv, ErrorDiv };
