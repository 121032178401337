import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import Videos from '../components/Videos';
import { useFirebase } from '../state';
import styled from 'styled-components';
import Select from 'react-select';

const ReactSelectElement = styled(Select)`
  width: 200px;
  display: table-cell;
`;

const Input = styled.input`
  width: 200px;
  display: table-cell;
  margin-right: 20px;
`;

const ControlBox = styled.div`
  margin-bottom: 50px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
  display: table;
`;

const options = [
  { value: '10', label: 'Show 10 Classes' },
  { value: '50', label: 'Show 50 Classes' },
  { value: '1000', label: 'Show All Classes' },
];

export default function () {
  const { firebase, user } = useFirebase();
  const [numVideos, setNumVideos] = useState(10);
  const [videos, setVideos] = useState([]);
  const [drafts, setDrafts] = useState([]);
  const [search, setSearch] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (!user.admin) {
      return;
    }
    let firestore = firebase.firestore();
    try {
      let query = firestore
        .collection('videos-v2')
        .where('published', '==', false)
        .orderBy('timestamp', 'desc');
      return query.onSnapshot(
        snapshot => {
          let output = [];
          if (!snapshot.empty) {
            for (const doc of snapshot.docs) {
              let d = doc.data();
              d.id = doc.id;
              output.push(d);
            }
          }
          setDrafts(output);
          setError('');
        },
        err => {
          console.log(err);
          setVideos([]);
          setError('Could not load videos');
        }
      );
    } catch (err) {
      console.log(err);
      setVideos([]);
      setError('Could not load draft videos');
    }
  }, [firebase, user]);

  useEffect(() => {
    let firestore = firebase.firestore();
    try {
      let query = firestore
        .collection('videos-v2')
        .where('published', '==', true)
        .orderBy('timestamp', 'desc');
      return query.onSnapshot(
        snapshot => {
          let output = [];
          if (!snapshot.empty) {
            for (const doc of snapshot.docs) {
              let d = doc.data();
              d.id = doc.id;
              output.push(d);
            }
          }

          if (search) {
            let searchTerm = search.toUpperCase();
            output = output.filter(vid => {
              if (vid.description.toUpperCase().includes(searchTerm)) {
                return true;
              } else if (vid.title.toUpperCase().includes(searchTerm)) {
                return true;
              } else if (vid.category.toUpperCase().includes(searchTerm)) {
                return true;
              }
              return false;
            });
          }
          setVideos(output.slice(0, numVideos));
          setError('');
        },
        err => {
          console.log(err);
          setVideos([]);
          setError('Could not load published videos');
        }
      );
    } catch (err) {
      console.log(err);
      setVideos([]);
      setError('Could not load published videos');
    }
  }, [firebase, user, numVideos, search]);

  if (!user) {
    return null;
  }

  return (
    <Layout title="Online Classes">
      <section className="mb4 pt4">
        <div className="container">
          <ControlBox>
            <Input
              className="input"
              type="text"
              placeholder="Search"
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
            <ReactSelectElement
              id="num-videos"
              options={options}
              defaultValue={options[0]}
              onChange={e => setNumVideos(e.value)}
            />
          </ControlBox>

          {error && <div className="notification is-danger">{error}</div>}

          {!user.active && !user.admin && (
            <div className="notification is-danger">
              Your account has not been activated.
            </div>
          )}

          {user.admin && <Videos user={user} videos={drafts} />}
          {videos && <Videos user={user} videos={videos} />}
        </div>
      </section>
    </Layout>
  );
}
