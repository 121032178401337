import React from 'react';
import SignIn from './pages/sign-in.js';
import Classes from './pages/classes.js';
import Account from './pages/account.js';
import { useFirebase, FirebaseWrapper } from './state';
import Admin from './pages/admin.js';
import { LoadingDiv } from './components/Divs';
import { Router } from '@reach/router';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn:
    'https://3982baa329b345fa9a0f6e17e293295e@o447635.ingest.sentry.io/5435383',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  release: process.env.REACT_APP_VERSION,
});
console.log('Sentry Release: ' + process.env.REACT_APP_VERSION);

const App = () => {
  const { user } = useFirebase();

  return (
    <div className="App">
      {user == null ? (
        <Router>
          <SignIn path="/sign-in" />
          <LoadingDiv default loading={true} />
        </Router>
      ) : (
        <Router>
          <Classes default />
          <Account path="/account" />
          <Admin path="/admin/*" />
        </Router>
      )}
    </div>
  );
};

export default function () {
  console.log('Rendering global');
  console.log('*** App... Rendering global');
  return (
    <FirebaseWrapper>
      <App></App>
    </FirebaseWrapper>
  );
}
