import React, { useState } from 'react';

export default function (props) {
  const [email, setEmail] = useState('');

  async function handler(e) {
    e.preventDefault();
    props.submitHandler(email);
  }

  return (
    <div>
      <form className="" onSubmit={handler}>
        <div className="field">
          <label className="label">Email</label>
          <div className="control ">
            <input
              className="input w5"
              type="text"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter Email"
            />
          </div>
        </div>
        <div className="field">
          <div className="control">
            <button className="button" id="loginSubmit">
              Reset password
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
