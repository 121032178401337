import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import LoginForm from '../components/LoginForm';
import ResetPasswordForm from '../components/ResetPasswordForm';
import Welcome from '../components/Welcome';
import { useFirebase } from '../state';
import styled from 'styled-components';
import img from '../static/yp-logo.jpg';
import AppStoreLink from '../components/AppStoreLink';

const LogoImage = styled.img`
  width: 400px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
  @media (max-width: 400px) {
    width: 300px;
  }
`;

const Footer = styled.footer`
  text-align: center;
  background-color: #fafafa;
  bottom: 0px;
  width: 100%;
  padding: 50px;
  margin-top: 50px;
`;

const H2 = styled.h2`
  font-family: 'Barlow', sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 1.8rem;
`;

const LoginFormWrapper = styled.div`
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  display: block;
`;

const Page = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
`;

export default function () {
  useEffect(() => {}, []);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showReset, setShowReset] = useState(false);
  const { firebase } = useFirebase();

  let ErrorDiv = function (props) {
    if (props.msg) {
      return <div className="notification is-danger">{props.msg}</div>;
    } else {
      return null;
    }
  };

  let LoadingDiv = function (props) {
    if (props.active) {
      return <progress className="progress is-success"></progress>;
    } else {
      return null;
    }
  };

  async function signIn(email, password) {
    try {
      setLoading(true);
      await firebase.auth().signInWithEmailAndPassword(email, password);
      navigate('/');
    } catch (err) {
      console.log(err);
      console.log(err.message);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  async function resetPassword(email) {
    setLoading(true);
    try {
      let fb = await firebase();
      await fb.auth().sendPasswordResetEmail(email);
    } catch (err) {
      console.log(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Page>
      <LogoImage src={img} alt="The Yoga Practice Logo" />
      <LoginFormWrapper>
        <section class="hero">
          <div class="hero-body">
            <div class="container">
              {!showReset ? (
                <div>
                  <H2>
                    Here is a preview from our <i>beginner</i> series. You can
                    sign in below to access all of our classes.
                  </H2>
                  <Welcome />
                </div>
              ) : (
                <h2 class="subtitle">
                  Enter your email address. If your account exists we will send
                  you a link to reset your password.
                </h2>
              )}
            </div>
          </div>
        </section>
        <section className="ma4 mt0">
          <div className="container">
            <ErrorDiv msg={error} />
            <LoadingDiv active={loading} />
            {showReset ? (
              <ResetPasswordForm submitHandler={resetPassword} />
            ) : null}
            {!showReset ? <LoginForm submitHandler={signIn} /> : null}
            <div className="mt2 tc">
              {!showReset ? (
                <a onClick={() => setShowReset(!showReset)}>Forgot password</a>
              ) : (
                <a onClick={() => setShowReset(!showReset)}>Back to sign-in</a>
              )}
            </div>
          </div>
        </section>
      </LoginFormWrapper>

      <Footer>
        <AppStoreLink />
        <div>
          <p>
            <strong>Online Classes</strong> by{' '}
            <a href="https://theyogapractice.com.au">The Yoga Practice</a>
          </p>
          <p>&copy; 2020 by Lyn Beeby</p>
        </div>
      </Footer>
    </Page>
  );
}
