import React, { useState, useEffect } from 'react';
import { useFirebase } from '../state';
import Player from './Player';

function Welcome() {
  const { firebase, user } = useFirebase();
  return (
    <div>
      <Player
        muxPlaybackId="i100nr5wslQ01JC9raLQ01Ew2sgf00XUUFziEZXzD4ntwtw"
      />
    </div>
  );
}

export default Welcome;
