import React from 'react';
function AppStoreLink() {
  return (
    <a
      href="https://apps.apple.com/au/app/the-yoga-practice/id1504789769?mt=8"
      style={{
        display: 'inline-block',
        overflow: 'hidden',
        background:
          'url(https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2020-07-15&kind=iossoftware&bubble=ios_apps) no-repeat',
        width: '135px',
        height: '40px',
      }}
    ></a>
  );
}
export default AppStoreLink;
