import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import { useFirebase } from '../state';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  .main {
    width: 100%;
    margin: 20px;
  }

  form {
    margin-top: 50px;
  }

  @media only screen and (min-width: 800px) {
    margin: 50px;
  }
`;

export default function (props) {
  const { firebase, user } = useFirebase();
  const [error, setError] = useState('');
  const [msg, setMsg] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);

  async function changePassword(e) {
    e.preventDefault();
    setLoading(true);
    try {
      let fb = await firebase();
      let user = fb.auth().currentUser;
      await fb.auth().signInWithEmailAndPassword(user.email, password);
      await user.updatePassword(newPassword);
      setPassword('');
      setNewPassword('');
      setMsg('Password change complete');
      setError('');
      console.log('Done');
    } catch (err) {
      console.log('Logging error');
      console.log(err);
      setError(err.message);
    }
    setLoading(false);
  }

  let ErrorDiv = function () {
    if (error) {
      return (
        <div className="notification is-danger">
          <button className="delete" onClick={() => setError(null)}></button>
          {error}
        </div>
      );
    } else {
      return null;
    }
  };

  let MsgDiv = function () {
    if (msg) {
      return (
        <div className="notification is-success">
          <button className="delete" onClick={() => setMsg(null)}></button>
          {msg}
        </div>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {}, []);

  if (!user) {
    return null;
  }

  return (
    <Layout title="Account - The Yoga Practice">
      <Wrapper>
        <div className="main">
          <ErrorDiv msg={error} />
          <MsgDiv msg={msg} />
          <p>
            Your email address is: <b>{user.email}</b>
          </p>
          <p>You can change your password by filling out the form below:</p>
          <form className="" onSubmit={changePassword}>
            <div className="field">
              <label className="label">Current Password</label>
              <div className="control">
                <input
                  className="input w5"
                  type="password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter Current Password"
                />
              </div>
            </div>

            <div className="field">
              <label className="label">New Password</label>
              <div className="control">
                <input
                  className="input w5"
                  type="password"
                  id="password"
                  name="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="Enter New Password"
                />
              </div>
            </div>

            <div className="field">
              <div className="control">
                <button
                  className={`button is-success ${loading ? 'is-loading' : ''}`}
                  id="loginSubmit"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </Wrapper>
    </Layout>
  );
}
