import React, { useState } from 'react';

export default function (props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  async function handler(e) {
    e.preventDefault();
    props.submitHandler(email, password);
  }

  return (
    <div className="box">
      <h2 className="title is-4">Create user</h2>
      <form className="ma2" onSubmit={handler}>
        <div className="field">
          <label className="label">Email</label>
          <div className="control ">
            <input
              className="input w5"
              type="text"
              id="email"
              name="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Enter Email"
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Password</label>
          <div className="control">
            <input
              className="input w5"
              type="text"
              id="password"
              name="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder="Enter Password"
            />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <button className="button is-success" id="loginSubmit">
              Create
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
