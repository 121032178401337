import React, { createContext, useContext, useState, useEffect } from 'react';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/remote-config';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/analytics';
import firebase from 'firebase/app';
import { navigate } from '@reach/router';
import jwt_decode from 'jwt-decode';

const AppContext = createContext();

export const FirebaseWrapper = ({ children }) => {
  const [fb, setFb] = useState(null);
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState(null);

  async function signOut() {
    await firebase.auth().signOut();
    navigate('/sign-in');
  }

  async function activateFirebase() {
    console.log('Activating Firebase');
    console.log('*** FirebaseWrapper... Activating Firebase');

    const config = {
      apiKey: 'AIzaSyAVm4RXrL5er6A8brC8kFpCSz74ohYeldQ',
      authDomain: 'the-yoga-practice.firebaseapp.com',
      databaseURL: 'https://the-yoga-practice.firebaseio.com',
      projectId: 'the-yoga-practice',
      storageBucket: 'the-yoga-practice.appspot.com',
      messagingSenderId: '80356761926',
      appId: '1:80356761926:web:15323328c09d43de6dc547',
      measurementId: 'G-43ZEH45QZ6',
    };

    if (firebase.apps.length === 0) {
      await firebase.initializeApp(config);
      firebase.analytics();
    }
    const remoteConfig = firebase.remoteConfig();
    remoteConfig.settings = {
      minimumFetchIntervalMillis: 3600000,
    };
    firebase.auth().onAuthStateChanged(async function (user) {
      if (user) {
        let token = await user.getIdToken(true);
        var decoded = jwt_decode(token);
        setUser(decoded);
      } else {
        console.log('No user logged in');
        navigate('/sign-in');
        setUser(null);
        console.log('*** FirebaseWrapper... No user logged in');
      }
    });

    setFb(firebase);
  }

  useEffect(() => {
    console.log(`*** FirebaseWrapper... useEffect`);
    if (user != null) {
      setEmail(user.email);
    }
  }, [user]);

  useEffect(() => {
    activateFirebase();
  }, []);

  return (
    <AppContext.Provider
      value={{
        user: user,
        email: email,
        firebase: fb,
        signOut: signOut,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useFirebase = () => useContext(AppContext);
