import React, { useState, useEffect } from 'react';
import { useFirebase } from '../state';
import Player from './Player';
import { FaClock } from 'react-icons/fa';
import styled from 'styled-components';
import Moment from 'react-moment';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  .box {
    width: 100%;
    margin: 10px;
  }
  @media only screen and (min-width: 800px) {
    .box {
      width: 30%;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const AdminBox = styled.div`
  background-color: grey;
  padding: 10px;
`;

function Videos(props) {
  const { firebase, user } = useFirebase();

  async function publish(id) {
    console.log(`Publishing: ${id}`);
    let firestore = firebase.firestore();
    try {
      await firestore
        .collection('videos-v2')
        .doc(id)
        .update({ published: true });
    } catch (err) {
      console.log(err);
    }
  }

  async function unpublish(id) {
    console.log(`Unpublishing: ${id}`);
    let firestore = firebase.firestore();
    try {
      await firestore
        .collection('videos-v2')
        .doc(id)
        .update({ published: false });
    } catch (err) {
      console.log(err);
    }
  }

  async function deleteVideo(id) {
    let firestore = firebase.firestore();
    console.log('Deleting');
    try {
      await firestore.collection('videos-v2').doc(id).delete();
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Wrapper>
      {props.videos.map(item => (
        <div className="box" key={item.filePath}>
          <p className="title is-4">{item.title}</p>
          <p className="subtitle is-5">{item.description}</p>
          <p className="is-6">Instructor: {item.instructor}</p>
          <span className="tag">{item.category}</span>
          <p className="subtitle is-7 mt2">
            {item.timestamp ? (
              <>
                <FaClock className="" />
                <span className="ml1">
                  <Moment unix fromNow>
                    {new Date(item.timestamp.seconds)}
                  </Moment>
                </span>
                -{' '}
                <Moment unix format="DD MMM YYYY">
                  {new Date(item.timestamp.seconds)}
                </Moment>
              </>
            ) : (
              'Now'
            )}
          </p>

          <Player
            filePath={item.filePath}
            muxPlaybackId={item.mux_playback_id}
          />
          {user.admin && (
            <AdminBox>
              {!item.published && (
                <button
                  className="button is-small is-danger mr2"
                  onClick={() => {
                    if (
                      window.confirm(
                        `Are you sure you wish to delete *${item.title}*?`
                      )
                    )
                      deleteVideo(item.id);
                  }}
                >
                  Delete
                </button>
              )}
              {item.published && (
                <button
                  className="button is-small is-warning mr2"
                  onClick={() => {
                    unpublish(item.id);
                  }}
                >
                  Unpublish
                </button>
              )}
              {!item.published && item.mux_playback_id && (
                <button
                  className="button is-small is-success mr2"
                  onClick={() => {
                    publish(item.id);
                  }}
                >
                  Publish
                </button>
              )}
              {item.published && !item.mux_playback_id && (
                <progress className="pa2 progress is-primary" />
              )}
            </AdminBox>
          )}
        </div>
      ))}
    </Wrapper>
  );
}
export default Videos;
